import React, { useEffect, useState } from 'react';
import Notification, {
	sendNotification,
} from '@appmaker/components/ReactHotToastIntegrator';

const convertJSON = (inputJSON) => {
	const { properties } = inputJSON;
	const convertedProperties = [];

	for (const key in properties) {
		const convertedProperty = {
			id: key,
			...properties[key],
		};
		convertedProperties.push(convertedProperty);
	}

	return {
		properties: convertedProperties,
	};
};

const InputJSON = {
	properties: {
		first: {
			type: 'string',
			label: 'Details1',
			description: 'Some description.',
		},
		second: {
			type: 'string',
			label: 'Details2',
			description: 'Some description',
		},
		third: {
			type: 'string',
			label: 'Details3',
			description: 'Some description.',
		},
		fourth: {
			type: 'string',
			label: 'Details4',
			description: 'Some description.',
		},
	},
};

const SplitScreen = () => {
	const [inputJSON, setInputJSON] = useState('');
	const [outputJSON, setOutputJSON] = useState(null);
	const [isCopied, setIsCopied] = useState(false);

	const handleInputChange = (event) => {
		setInputJSON(event.target.value);
	};

    const handleCopyClick = () => {
        navigator.clipboard.writeText(JSON.stringify(outputJSON));
        setIsCopied(true);
      }

	const handleConvertClick = () => {
		try {
			const parsedJSON = JSON.parse(inputJSON);
			const convertedJSON = convertJSON(parsedJSON);
			setOutputJSON(convertedJSON);
			sendNotification('Generated !', 'success');
            setIsCopied(false);
		} catch (error) {
			console.error('Invalid JSON input:', error);
			sendNotification('Invalid JSON input !!', 'error');
			setOutputJSON(null);
		}
	};
    useEffect(()=>{
        if(isCopied){
            sendNotification('Copied to clipboard',undefined)
            setTimeout(()=>{
                setIsCopied(false);
            },5000)
        }
    },[isCopied])
	return (
		<Notification>
			<div className="flex h-screen">
				<div className="w-1/2 p-8 bg-gray-200">
					{/* Input JSON */}
                    <p className='text-xl mb-4'>Appmaker form Object to Array converter</p>
					<h1 className="text-xl font-bold mb-4">Input JSON:</h1>
                    
					<textarea
						className="w-full h-5/6 p-2 bg-white border border-gray-300 rounded resize-none"
						value={inputJSON}
						onChange={handleInputChange}
						placeholder="Enter JSON here"
					></textarea>
					<button
						className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
						onClick={handleConvertClick}
					>
						Convert
					</button>
				</div>
				<div className="w-1/2 p-8 bg-gray-100">
					{/* Output JSON */}
					<h1 className="text-xl font-bold mb-4">Output JSON:</h1>
					<button
						className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
						onClick={handleCopyClick}
					>
						{isCopied ? 'Copied!' : 'Copy to Clipboard'}
					</button>
					<pre>
						{outputJSON ? JSON.stringify(outputJSON, null, 2) : 'No output'}
					</pre>
				</div>
			</div>
		</Notification>
	);
};

export default SplitScreen;
