import React, { useState } from 'react';
import { useBlockProps } from '@wordpress/block-editor';
import AppmakerForm from '@appmaker/core/components/AppmakerForm';
import {
  ToolbarGroup
} from '@wordpress/components';
import {
  BlockControls,
} from '@wordpress/block-editor';
import { PencilIcon } from '@heroicons/react/solid';
import { collection } from '../../api';

import ProductListView from './ProductListView'
import { InspectorControls } from '@wordpress/block-editor';
import { PanelBody, RangeControl, ToggleControl } from '@wordpress/components';
import { extractTextFromHTML } from '../helper/index';
import {
  RichText
} from '@wordpress/block-editor';
import { BlockCard } from '@appmaker/e-commerce-blocks/components';
import EmptyTemplate from 'packages/blockEditor/components/BlockEditor/components/EmptyTemplate';

const options = {
  PRODUCTS_BY_COLLECTION: {
    label: "Products by collection",
    properties: {
      collectionId: {
        type: 'promise-select',
        label: "Collection",
        getOptions: async (inputValue, { projectId }) => {
          const collections = await collection({ search: inputValue });
          const finalList = collections.map(item => ({ label: item.label, value: item.id }))
          return finalList;
        }
      },
    }
  }
};
function ActionSelector(
  { action, setAction }
) {
  const schema = options
  return (
    <div>

      {/* <StateView json={action} /> */}
      <AppmakerForm
        options={options}
        value={{ ...action, schema }}
        optionKey={'type'}
        setValue={setAction}
        debug={false}
      />
    </div>
  )
}

export default function ProductScroller({ attributes, setAttributes }) {
  const { config = {}, ___internal, showViewMoreButton, showTitle } = attributes
  const { collectionId } = config
  const [isEditing, setIsEditing] = useState(true)
  const getBlockControls = () => {
    return (
      <BlockControls>
        <ToolbarGroup
          controls={[
            {
              icon: <PencilIcon />,
              title: 'Edit',
              onClick: () => setIsEditing(!isEditing),
              isActive: isEditing,
            },
          ]}
        />
      </BlockControls>
    );
  };
  return (
    <div  {...useBlockProps()}>
      <InspectorControls>
        <PanelBody title="Product Scroller">
          <ToggleControl
            label={'Show View more button'}
            checked={showViewMoreButton}
            onChange={() => {
              setAttributes({ showViewMoreButton: !showViewMoreButton });
            }}
          />
          <ToggleControl
            label={'Show Title'}
            checked={showTitle}
            onChange={() => {
              setAttributes({ showTitle: !showTitle });
            }}
          />
        </PanelBody>
      </InspectorControls>
      {/* ProductDetail : - ${JSON.stringify(___internal)} */}
      <BlockCard
        titleEditor={(
          <RichText
            allowedFormats={[]}
            value={attributes.title}
            withoutInteractiveFormatting
            onChange={(title) => {
              setAttributes({ title: extractTextFromHTML(title) });
            }}
            placeholder="Grid title"
          />
        )}
        attributes={{
          showTitle: showTitle,
          accessButton: showViewMoreButton && (
            <RichText
              allowedFormats={[]}
              value={attributes.ctaText}
              withoutInteractiveFormatting
              onChange={(ctaText) => {
                setAttributes({ ctaText: extractTextFromHTML(ctaText) });
              }}
              placeholder="View More Button"
            />
          ),
        }}
      >
        <EmptyTemplate attributes={
          {
            title: 'Product Horizonal Scrollers',
            description: 'Horizontally list your products based on Collection '
          }
        }>
          <div className="p-2 w-full">
            {isEditing &&
              <ActionSelector
                action={{ formData: config, _internal: ___internal }}
                setAction={({ formData, _internal }) => {
                  setAttributes({ config: formData, ___internal: _internal });
                }}
              />
            }

          </div>
        </EmptyTemplate>
        <ProductListView collectionId={collectionId} />
        {getBlockControls()}

      </BlockCard>

    </div>
  )
}
