import ColorsGroup, { ColorPickerField } from './ColorsGroup';
import FileUpload from './FileUpload';
import FieldGroup from './FieldGroup';
import AppmakerSwitch from './AppmakerSwitch';
import AppmakerRadioGroup from './RadioGroup';
import Input from './TextInput';
import CheckBox from './CheckBox';
import RangeSlider from './RangeSlider';
import DataSourceSelector from './CustomForms/DataSourceSelector';
import LayoutSelector from './CustomForms/LayoutSelector';
import DataSelector from './CustomForms/DataSelector';
import AppmakerActionFormItem from './AppmakerActions';
import Media from './Media';
// import ColorPicker from './components/ColorPicker';
import ImageRadio from './ImageRadio';

const fields = {
	ColorsGroup,
	LaunchScreen: FieldGroup,
	FieldGroup,
	FileUpload,
	ColorPicker: ColorPickerField,
	Input,
	Switch: AppmakerSwitch,
	RadioGroup: AppmakerRadioGroup,
	CheckBox,
	DataSourceSelector,
	LayoutSelector,
	DataSelector,
	RangeSlider,
	'appmaker-action': AppmakerActionFormItem,
	'appmaker-media-library': Media,
	'image-radio': ImageRadio,
};
export { fields };
