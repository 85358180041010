import { useThemeList } from '../store';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import { CubeIcon, SearchIcon } from '@heroicons/react/outline';
import { isAdmin as checkIsAdmin } from '@appmaker/core/store/user';
import ActiveThemeCard from './ActiveThemeCard';
import ThemeCard from './ThemeCard';
import AddTheme from './AddTheme';

const List = () => {
	const themeList = useThemeList((data) => data.list);
	const activeTheme = useThemeList((data) => data.activeTheme);
	const getRouterPath = useRouterPath();
	const isAdmin = checkIsAdmin();
	// const installed = true;
	const checkInstalled = (status) => {
		return status === 'active';
	};
	const checkActive = (themeId) => {
		return activeTheme && themeId === activeTheme.themeId;
	};
	const installedThemes = themeList
		? themeList.filter((item) => item.installedMeta)
		: [];

	return (
		<div className="bg-gray-50 h-screen overflow-y-auto">
			<div className="bg-white  shadow-sm grid grid-cols-2 sm:grid-cols-3 gap-4 px-4 py-2 items-center">
				<h2 className="text-md font-medium">Themes</h2>
				<div className="relative border border-gray-100 rounded-md text-gray-400 focus-within:text-gray-600">
					<div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
						<SearchIcon className="h-5 w-5" aria-hidden="true" />
					</div>
					<input
						id="search"
						className="block w-full bg-gray-50 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm"
						placeholder="Search theme"
						type="search"
						name="search"
						onChange={(event) => {
							// searchExtension(event.target.value)
						}}
					/>
				</div>
				<AddTheme />
			</div>
			<div className="max-w-2xl  mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
				<div className="flex items-center justify-between space-x-4 ">
					<h2 className="text-lg font-medium text-gray-900">Active Theme</h2>
				</div>
				<div className="mt-4 grid grid-cols-1">
					{activeTheme ? (
						<ActiveThemeCard
							theme={{
								image:
									'https://maxcdn.icons8.com/style-preview/bbf47db7-8a8b-41b6-b218-6c2697e637f0.jpeg',
								name: 'Theme Name',
								version: 'v1.0.0',
								author: 'Author Name',
							}}
							getRouterPath={getRouterPath}
						/>
					) : (
						<div className="p-4 group rounded-xl bg-white flex flex-col justify-center items-center border border-dashed border-gray-200">
							<CubeIcon className="h-16 w-16 p-4 bg-gray-200 rounded-full" />
							<h3 className="text-lg font-medium text-gray-900">
								No Active Theme
							</h3>
							<p className="text-sm text-gray-500">
								Please install a theme to activate
							</p>
						</div>
					)}
				</div>
			</div>
			<div className="max-w-2xl  mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
				<div className="flex items-center justify-between space-x-4 ">
					<h2 className="text-lg font-medium text-gray-900">
						Installed Themes
					</h2>
				</div>
				<div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-y-6 lg:grid-cols-3">
					{installedThemes.length === 0 ? (
						<div className="p-4 group rounded-xl bg-white flex flex-col justify-center items-center border border-dashed border-gray-200">
							<CubeIcon className="h-16 w-16 p-4 bg-gray-200 rounded-full" />
							<h3 className="text-md font-medium text-gray-400 mt-3">
								No active themes
							</h3>
						</div>
					) : null}
					{installedThemes.map((product) => (
						<ThemeCard
							key={product.id}
							getRouterPath={getRouterPath}
							theme={product}
							checkActive={checkActive}
						/>
					))}
				</div>
			</div>
			{isAdmin && (
				<div className="max-w-2xl mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
					<div className="flex items-center justify-between space-x-4">
						<h2 className="text-lg font-medium text-gray-900">
							Available Themes
						</h2>
					</div>
					<div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-y-6 lg:grid-cols-3">
						{themeList.map((product) => (
							<ThemeCard
								key={product.id}
								getRouterPath={getRouterPath}
								theme={product}
								checkInstalled={checkInstalled}
							/>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default List;
