import { gql } from '@apollo/client';

export const GET_EXTENSION_INSTALLS = gql(`
    query (
        $installWhere: ExtensionInstallWhereInput!
        $extensionWhere: ExtensionWhereUniqueInput!
    ) {
        extensionInstalls(where: $installWhere) {
        id
        status
        settings
        extension {
            id
            name
            handle
        }
        }
        extension(where: $extensionWhere) {
        id
        handle
        name
        settingsSchema
        backendSchema
        createdAt
        updatedAt
        description
        packageVersion
        icon,
        type
        }
    }
`);

export const POST_EXTENSION_INSTALL = gql(`
    mutation($data: ExtensionInstallCreateInput!){
        createExtensionInstall(data: $data) {
          id,
        }
      }
`);

export const EXTENSION_INSTALLED_LIST = gql(`
    query($where: ExtensionInstallWhereInput!){
        extensionInstalls(where: $where) {
        androidVersion,
        extension {
            backendSchema,
            createdAt,
            updatedAt,
            description,
            handle,
            id,
            name,
            settingsSchema,
            packageVersion,
            icon
            type
        }
        status,
        id
        }
    }
`);

export const GET_EXTENSION = gql(`
    query ($where: ExtensionWhereUniqueInput!) {
        extension(where: $where) {
        backendSchema
        createdAt
        description
        handle
        id
        name
        settingsSchema
        }
    }
`);
export const UPDATE_SETTINGS = gql(`
    mutation($where: ExtensionInstallWhereUniqueInput!, $data: ExtensionInstallUpdateInput!){
        updateExtensionInstall(where: $where, data: $data) {
          id,
          settings
        }
      }
`);

export const UNINSTALL_EXTENSION = gql(
	`
    mutation($where: ExtensionInstallWhereUniqueInput!){
        deleteExtensionInstall(where: $where) {
          id
        }
      }
      `
);
