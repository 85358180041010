import { InnerBlocks } from '@wordpress/block-editor';
import { withSelect } from '@wordpress/data';
import React from 'react';
import { MediaPlaceholder } from 'packages/blockEditor/components/BlockEditor/components/MediaPlaceHolder';
import clsx from 'clsx';

function Icon({ color, name, size = 18 }) {
	return (
		<div className={name ? null : 'bg-gray-500 rounded'}>
			<svg
				width={size}
				height={size}
				fill="none"
				stroke={color || '#212121'}
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			>
				<use href={`/feather-sprite.svg#${name}`} />
			</svg>
		</div>
	);
}

export function BottomTabItemChild({
	attributes,
	setAttributes,
	isSelected,
	parentAttributes,
}) {
	const {
		defaultImage,
		activeImage,
		defaultbase64Icon,
		activebase64Icon,
		fontWeight = 'bold',
	} = attributes;
	const defaultImageSrc = defaultImage?.url || defaultbase64Icon;
	const activeImageSrc = activeImage?.url || activebase64Icon;
	return (
		<div className="bg-transparent m-1 rounded overflow-hidden flex items-center space-x-2">
			{isSelected && (
				<MediaPlaceholder
					attributes={{
						// icon: ViewGridIcon,
						title: 'Default Icon',
						description: '',
					}}
					onSelect={(images) => {
						let image = Array.isArray(images) ? images[0] : images;
						const data = image;
						setAttributes({
							defaultImage: {
								url: data.url,
								fileName: data.filename,
								id: data.id,
								title: data.title,
								height: data.meta !== undefined ? data.meta.height : '100',
								width: data.meta !== undefined ? data.meta.width : '100',
							},
						});
					}}
					allowedTypes={['image']}
					multiple={false}
					labels={{ title: 'Image' }}
					value={defaultImage}
				/>
			)}
			{isSelected && (
				<MediaPlaceholder
					attributes={{
						title: 'Active Icon',
						description: '',
					}}
					onSelect={(images) => {
						let image = Array.isArray(images) ? images[0] : images;
						const data = image;
						setAttributes({
							activeImage: {
								url: data.url,
								fileName: data.filename,
								id: data.id,
								title: data.title,
								height: data.meta !== undefined ? data.meta.height : '100',
								width: data.meta !== undefined ? data.meta.width : '100',
							},
						});
					}}
					allowedTypes={['image']}
					multiple={false}
					labels={{ title: 'Image' }}
					// value={{ id: image && image.id, src: image && image.url }}
				/>
			)}
			{defaultImageSrc || activeImageSrc ? (
				<>
					<img
						src={defaultImageSrc}
						alt="icon"
						style={{
							height: `${parentAttributes?.iconSize}px` || '18px',
							width: `${parentAttributes?.iconSize}px` || '18px',
						}}
					/>
					<img
						src={activeImageSrc}
						alt="icon"
						style={{
							height: `${parentAttributes?.iconSize}px` || '18px',
							width: `${parentAttributes?.iconSize}px` || '18px',
						}}
					/>
				</>
			) : (
				<>
					<Icon
						name={attributes.iconName}
						color={attributes.defaultColor}
						size={parentAttributes?.iconSize || 18}
						alt="default"
					/>
					<Icon
						name={attributes.iconName}
						color={attributes.activeColor}
						size={parentAttributes?.iconSize || 18}
						alt="active"
					/>
				</>
			)}
			<div
				style={{
					color: attributes.activeColor || '#000000',
					fontSize: `${parentAttributes?.fontSize}px` || '11px',
				}}
				className={clsx({
					'font-bold': fontWeight === 'bold',
					'font-medium': fontWeight === 'medium',
					'font-regular': fontWeight === 'regular',
				})}
			>
				{attributes.label}
			</div>
		</div>
	);
}
export const BottomTabItem = withSelect((select, props) => {
	const parentId = select('core/block-editor').getBlockRootClientId(
		props.clientId
	);
	const parentAttributes =
		select('core/block-editor').getBlockAttributes(parentId);
	return {
		...props,
		parentAttributes,
	};
})(BottomTabItemChild);

BottomTabItem.attributesSchema = {
	properties: {
		label: {
			type: 'string',
			label: 'Label',
			source: 'attribute',
		},
		pageId: {
			type: 'string',
			label: 'Page Id',
			source: 'attribute',
		},
		openSeperatePage: {
			type: 'string',
			label: 'Open as separate page',
			uiType: 'switch',
		},
		iconName: {
			type: 'string',
			label: (
				<>
					Icon Name{' '}
					<a
						href="https://feathericons.com/"
						target="_blank"
						rel="noopener noreferrer"
						className="text-blue-500"
					>
						(Feather Icons)
					</a>
				</>
			),
			source: 'attribute',
		},
		activeColor: {
			type: 'string',
			label: 'Active Color',
			source: 'attribute',
			uiType: 'ColorPicker',
		},
		defaultColor: {
			type: 'string',
			label: 'Default Color',
			source: 'attribute',
			uiType: 'ColorPicker',
		},
		defaultbase64Icon: {
			type: 'string',
			label: 'Default Base64 Icon',
			source: 'attribute',
		},
		activebase64Icon: {
			type: 'string',
			label: 'Active Base64 Icon',
			source: 'attribute',
		},
		specialItemBg: {
			type: 'string',
			label: 'Special Item Bg Color',
			source: 'attribute',
			uiType: 'ColorPicker',
		},
		count: {
			type: 'string',
			label: 'Count',
			source: 'attribute',
		},
		routeName: {
			type: 'string',
			label: 'Route Name',
			source: 'attribute',
		},
		toolBarCustomStyles: {
			type: 'json',
			label: 'Custom Styles',
			source: 'attribute',
		},
		fontWeight: {
			type: 'string',
			title: 'Font Weight',
			uiType: 'RadioGroup',
			defaultValue: 'bold',
			options: [
				{ label: 'Bold', value: 'bold' },
				{ label: 'Medium', value: 'medium' },
				{ label: 'Regular', value: 'regular' },
			],
		},
	},
};

export default function BottomTabBlock({ attributes }) {
	return (
		<div
			style={{ backgroundColor: attributes.tabBackgroundColor || '#FFFFFF' }}
		>
			<InnerBlocks allowedBlocks={['appmaker/bottom-tab-item']} />
		</div>
	);
}

BottomTabBlock.attributesSchema = {
	properties: {
		fontSize: {
			type: 'string',
			label: 'Font Size',
			uiType: 'RangeSlider',
			default: 11,
			min: 10,
			max: 20,
		},
		iconSize: {
			type: 'string',
			label: 'Icon Size',
			uiType: 'RangeSlider',
			default: 18,
			min: 12,
			max: 26,
		},
		tabBackgroundColor: {
			type: 'string',
			label: 'Background Color',
			source: 'attribute',
			uiType: 'ColorPicker',
			defaultColor: '#ffffff',
		},
		customTabStyles: {
			type: 'json',
			label: 'Custom Styles',
			source: 'attribute',
			description: (
				<span className="mb-4 block text-xs">Custom styles for the tab</span>
			),
		},
	},
};
