import clsx from 'clsx';
import { Link } from 'react-router-dom';

function ThemeCard(props) {
	const {
		key,
		getRouterPath,
		theme,
		checkActive = () => {},
		checkInstalled = () => {},
	} = props;
	return (
		<Link
			key={key}
			to={getRouterPath('/themes/' + theme.id)}
			className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md relative"
		>
			{checkActive(theme.themesId) && (
				<span className="bg-green-100 text-green-700 px-1 text-sm absolute right-4 top-0 rounded-b">
					Active
				</span>
			)}
			{checkInstalled(theme.status) && (
				<span className="bg-blue-100 text-blue-700 px-1 text-sm absolute right-4 top-0 rounded-b">
					Installed
				</span>
			)}
			<img
				src={theme.image}
				alt={theme.name}
				className="w-full h-32 object-cover object-top"
			/>
			<div className="p-3 flex items-start justify-between">
				<div className="flex items-start space-x-2">
					<img
						src={theme.image}
						alt={theme.name}
						className="object-contain object-center h-6 w-6 rounded-md"
					/>
					<h3 className="text-md font-medium group-hover:text-blue-600 capitalize line-clamp-1">
						{theme.name}
					</h3>
				</div>
				<h4
					className={clsx(
						'text-md',
						theme?.price === 0 ? 'text-green-600' : 'text-gray-700'
					)}
				>
					{theme?.price === 0 ? 'Free' : `$ ${theme?.price}`}
				</h4>
			</div>
		</Link>
	);
}

export default ThemeCard;
