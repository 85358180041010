import { collection } from 'packages/shopify/api';
import { useState, useCallback, useEffect } from 'react';
import Loader from '@appmaker/components/Loading';
import Select from '../CustomProductVariant/Select';
import debounce from 'lodash/debounce';

const getOptions = async (query) => {
	try {
		const response = await collection({
			search: query,
		});
		return response;
	} catch (error) {
		return [];
	}
};
const CustomFiled = (props) => {
	const { onChange = () => {}, defaultValue, properties } = props || {};
	const [isLoading, setLoading] = useState(false);
	const [options, setOptions] = useState([]);
	const searchProduct = useCallback(
		debounce(async (query) => {
			const response = await getOptions(
				query && query.length !== 0 ? query : undefined
			);
			setOptions(response);
		}, 500),
		[]
	);
	useEffect(() => {
		(async () => {
			setLoading(true);
			const response = await getOptions();
			setOptions(response);
			setLoading(false);
		})();
	}, []);
	if (isLoading) {
		return <Loader single small fullWidth />;
	}
	return (
		<div>
			<Select
				label={properties?.label ?? 'Choose collection'}
				value={options.find((item) => defaultValue && item.id === defaultValue)}
				onChange={(value) => {
					if (value && value.id) {
						onChange(value.id);
					}
				}}
				options={options}
				onSearch={searchProduct}
			/>
		</div>
	);
};

export default CustomFiled;
