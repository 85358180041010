import { useEffect, useState } from 'react';
import { useThemeList, setThemeList } from '../store';
import { useParams, Link } from '@appmaker/core/routes';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { useAsync } from 'react-use';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import { useProject } from '@appmaker/core/hooks';
import {
	createStore,
	AppmakerFormProvider,
	useAppmakerFormStoreApi,
} from '@appmaker/core/components/AppmakerForm/store';
import AppmakerForm from '@appmaker/core/components/AppmakerForm/Form';
import api from '../api';
import LoadingIcon from '@appmaker/components/LoadingIcon';
import Loader from '@appmaker/components/Loading';
import { isAdmin as checkIsAdmin } from '@appmaker/core/store/user';
import DropDownMenu from '@appmaker/components/DropDownMenu';
import { DotsVerticalIcon } from '@heroicons/react/solid';

const AppHeader = (props) => {
	const getRouterPath = useRouterPath();
	const [isLoading, setLoading] = useState(false);
	const [isActivating, setActivating] = useState(false);
	const { projectId } = useProject();
	const themeList = useThemeList((data) => data.list);
	const { themesId, image, name, status, id } = props.theme || {};
	const isAdmin = checkIsAdmin();

	const [isThemeInstalled, setActivationStatus] = useState(status === 'active');
	const onClickInstall = async () => {
		setLoading(true);
		try {
			const response = await api.installTheme({
				projectId,
				themeId: themesId,
			});
			const { id: projectThemeId, theme_id } = response.theme || {};
			const updateList = themeList.map((item) => {
				if (item.themesId === theme_id) {
					return {
						...item,
						status: 'active',
						installedMeta: {
							projectThemeId,
						},
					};
				}
				return item;
			});
			setThemeList({ list: updateList });
			setActivationStatus(true);
		} catch (error) {}
		setLoading(false);
	};
	const onClickDeactivate = async () => {
		setLoading(true);
		try {
			const response = await api.deactivateTheme({
				projectId,
				themeId: themesId,
			});
			const { id: projectThemeId, theme_id } = response.theme || {};
			const updateList = themeList.map((item) => {
				if (item.themesId === theme_id) {
					return {
						...item,
						status: 'inactive',
						installedMeta: {
							projectThemeId,
						},
					};
				}
				return item;
			});
			setThemeList({ list: updateList });
			setActivationStatus(false);
			setLoading(false);
		} catch (error) {}
	};

	const onActive = async () => {
		setActivating(true);
		try {
			const response = await api.activateTheme({
				projectId: projectId,
				data: {
					themeId: themesId,
					themeTag: id,
				},
			});
			setActivating(false);
		} catch (error) {}
	};

	return (
		<div className="flex items-center justify-between space-x-3 bg-white shadow-sm px-4 py-2">
			<div className="flex items-center space-x-3">
				<Link to={getRouterPath('/themes/')}>
					<ArrowLeftIcon className="h-8 w-8 p-2 hover:bg-gray-300 rounded-md text-gray-600" />
				</Link>
				<div className="aspect-square rounded-lg overflow-hidden bg-gray-100 h-10 w-10">
					<img
						src={image}
						alt={name}
						className="object-center object-contain h-10 w-10"
					/>
				</div>
				<div>
					<h4 className="text-lg font-semibold text-gray-900">{name}</h4>
				</div>
			</div>

			{isAdmin && (
				<div className="flex items-center space-x-2">
					{isThemeInstalled ? (
						<>
							<button
								disabled={isActivating}
								type="button"
								onClick={onActive}
								className="inline-flex font-medium justify-self-end bg-green-50 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base text-green-600 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-green-500"
							>
								{isActivating ? 'Loading... ' : ` Activate`}
							</button>
							{/* <button
								disabled={isActivating || isLoading}
								type="button"
								onClick={onClickDeactivate}
								className="inline-flex font-medium justify-self-end bg-red-50 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base text-red-600 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
							>
								{isLoading ? 'Loading....' : 'Uninstall themes'}
							</button> */}
						</>
					) : (
						<>
							<button
								disabled={isLoading}
								type="button"
								onClick={onClickInstall}
								className="inline-flex justify-self-end bg-blue-600 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base font-normal text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
							>
								{isLoading ? 'Loading... ' : `Install `}
							</button>
						</>
					)}
					<DropDownMenu
						options={[
							{
								label: 'Delete',
								onClick: onClickDeactivate,
							},
						]}
					/>
				</div>
			)}
		</div>
	);
};

const tabs = [
	{ id: 'details', name: 'Details', current: true },
	{
		id: 'settings',
		name: 'Settings',
		current: false,
		restrictOnDeactivation: true,
		metaKey: 'settings',
	},
	// {
	// 	id: 'admin',
	// 	name: 'Admin Settings',
	// 	current: false,
	// 	restrictOnDeactivation: true,
	// 	metaKey: 'admin_settings',
	// },
];
const SaveButton = ({ onClickSave, metaKey }) => {
	const { projectId } = useProject();
	const [isSaving, setSaving] = useState(false);
	const storeApi = useAppmakerFormStoreApi();
	const themeList = useThemeList((data) => data.list);
	let { themeId } = useParams();
	const themes = themeList.find((theme) => theme.id === themeId) || {};
	const onClickSaveButton = async () => {
		const { installedMeta: { projectThemeId } = {} } = themes;
		const { formData } = storeApi.getState();
		try {
			setSaving(true);
			const response = await api.themeSettings({
				projectId,
				metaKey,
				metaValue: formData,
				projectThemeId: projectThemeId,
			});
		} catch (error) {}
		setSaving(false);
	};

	return (
		<button
			disabled={isSaving}
			type="button"
			onClick={onClickSaveButton}
			className="my-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
		>
			{isSaving ? (
				<>
					<LoadingIcon className="-ml-1 mr-2 h-6 w-6 animate-spin" />
					Saving...
				</>
			) : (
				'Save'
			)}
		</button>
	);
};
const FormSchema = ({ schema, projectId, metaKey }) => {
	return (
		<div className="max-w-3xl">
			<AppmakerForm
				formVersion={2}
				// value={{ formData: flatten(formData) }}
				schema={schema}
			/>
			<SaveButton projectId={projectId} metaKey={metaKey} />
		</div>
	);
};

const FormSchemaWrapper = ({
	themeId,
	schema = {},
	metaKey,
	theme = {},
} = {}) => {
	const { projectId } = useProject();
	const { installedMeta: { projectThemeId } = {} } = theme;
	const { value, error, loading } = useAsync(async () => {
		const result = api.getValues({ projectId, projectThemeId, metaKey });
		return result;
	}, []);

	return loading ? (
		<div className="flex-1 overflow-hidden animate-pulse gap-4 flex flex-col">
			<div className="h-16 w-full bg-gray-200 rounded-lg" />
			<div className="h-16 w-full bg-gray-100 rounded-lg" />
			<div className="h-16 w-full bg-gray-50 rounded-lg" />
		</div>
	) : error ? (
		<div className="text-red-500">Error: {error.message}</div>
	) : (
		<div>
			<AppmakerFormProvider
				createStore={createStore({
					formData: value ? value : {},
				})}
			>
				<FormSchema schema={schema} projectId metaKey={metaKey} />
			</AppmakerFormProvider>
		</div>
	);
};
const TabSchemaWrapper = ({ tabId, theme = {}, key } = {}) => {
	const tab = tabs.find((item) => item.id === tabId) || {};
	if (tabId === 'details') {
		return null;
	}
	if (tabId === 'settings') {
		return (
			<FormSchemaWrapper
				key="settings_wrapper"
				theme={theme}
				themeId={theme.id}
				schema={theme.schema}
				metaKey={tab.metaKey ? tab.metaKey : undefined}
			/>
		);
	}
	if (tabId === 'admin') {
		return (
			<FormSchemaWrapper
				key="admin_wrapper"
				theme={theme}
				themeId={theme.id}
				schema={theme.backendSchema}
				metaKey={tab.metaKey ? tab.metaKey : undefined}
			/>
		);
	}
};

const ThemeContainer = ({ children, theme = {} }) => {
	const { status } = theme || {};
	const installed = status === 'active';
	return (
		<Tab.Group as={'div'} className="h-full">
			<Tab.List className="flex space-x-4 items-center bg-white px-6 mt-px shadow-sm">
				{tabs.map((tab) => {
					if (tab.restrictOnDeactivation && !installed) {
						return null;
					}
					return (
						<Tab
							key={tab.id}
							className={({ selected }) =>
								clsx(
									selected
										? 'border-indigo-500 text-indigo-600'
										: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
									'whitespace-nowrap pb-2 pt-4 px-1 border-b-2 font-medium text-sm'
								)
							}
						>
							{tab.name}
						</Tab>
					);
				})}
			</Tab.List>
			<Tab.Panels className="px-6 pt-8">
				<Tab.Panel className="mb-4 bg-white max-w-2xl p-4 shadow-sm rounded-lg">
					{theme.description}
					{/* TODO - screenshots */}
					{theme.screenshots && (
						<div className="flex items-start gap-2 overflow-x-auto mt-3">
							{theme.screenshots.map((image) => (
								<img src={image} alt="screen" className="max-w-sm h-auto" />
							))}
						</div>
					)}
				</Tab.Panel>
				{children ? (
					<Tab.Panel className="mb-4 bg-white max-w-2xl p-4 shadow-sm rounded-lg">
						{children}
					</Tab.Panel>
				) : (
					<Tab.Panel className="mb-4 bg-white max-w-2xl p-4 shadow-sm rounded-lg">
						<TabSchemaWrapper key="settings" theme={theme} tabId="settings" />
					</Tab.Panel>
				)}
				{children ? (
					<Tab.Panel className="mb-4 bg-white max-w-2xl p-4 shadow-sm rounded-lg">
						{children}
					</Tab.Panel>
				) : (
					<Tab.Panel className="mb-4 bg-white max-w-2xl p-4 shadow-sm rounded-lg">
						<TabSchemaWrapper key="admin" theme={theme} tabId="admin" />
					</Tab.Panel>
				)}
			</Tab.Panels>
		</Tab.Group>
	);
};

const ThemePreview = () => {
	const { projectId } = useProject();
	const [isLoading, setLoading] = useState(false);
	const themeStore = useThemeList();
	const themeList = themeStore.list;
	const isThemeMetaLoader = themeStore.setValue;
	let { themeId } = useParams();
	const theme = themeList.find((item) => item.id === themeId);
	const { Component } = theme || {};
	useEffect(() => {
		if (!isThemeMetaLoader) {
			(async () => {
				setLoading(true);
				try {
					const response = await api.getList({ projectId });
					const { themes = [], active = {} } = response;
					setThemeList({
						list: themes,
						setValue: true,
						originalList: themes,
						activeTheme: active,
					});
					setLoading(false);
				} catch (error) {}
			})();
		}
	}, []);
	if (isLoading) {
		return <Loader />;
	}
	return (
		<div className="bg-gray-50 h-screen overflow-y-auto">
			<div className="w-full mx-auto relative">
				<AppHeader theme={theme} />
				<ThemeContainer theme={theme}>
					{Component ? <Component /> : null}
				</ThemeContainer>
			</div>
		</div>
	);
};

export default ThemePreview;
