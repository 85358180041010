import { tags } from 'packages/shopify/api';
import { useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';

const getOptions = async (query) => {
	try {
		const response = await tags({
			search: query,
		});
		return response;
	} catch (error) {
		return [];
	}
};
const CustomTag = (props) => {
	const { properties, onChange = () => {}, defaultValue } = props;
	const { label, isMulti = true } = properties;
	const loadOptions = useCallback(
		debounce(async (inputValue, callback) => {
			const query = inputValue?.length === 0 ? undefined : inputValue;
			const response = await getOptions(query);
			const options = response?.map((item) => {
				return {
					...item,
					label: item?.label,
					value: item?.id,
				};
			});
			callback(options);
		}, 500)
	);

	return (
		<div className={label && 'mt-4'}>
			{label && (
				<label className="block text-sm font-base text-gray-700 mb-1">
					{label}
				</label>
			)}
			<AsyncSelect
				defaultValue={defaultValue}
				cacheOptions
				defaultOptions
				loadOptions={loadOptions}
				isMulti={isMulti}
				onChange={(selection) => {
					onChange(isMulti ? selection : selection.value, selection);
				}}
			/>
		</div>
	);
};

export default CustomTag;
